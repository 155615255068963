document.addEventListener('DOMContentLoaded', function() {

  // Allow header/main nav dropdowns on desktop to open via hover
  var dropdowns = document.querySelector('header').querySelectorAll('.dropdown');

  dropdowns.forEach(dropdown => {
    var dropdownToggle = dropdown.querySelector('.dropdown-toggle');
    var dropdownMenu = dropdownToggle.nextElementSibling;
    dropdown.addEventListener('mouseenter', () => {
      dropdownToggle.classList.add('show');
      dropdownToggle.setAttribute('aria-expanded', 'true');
      dropdownMenu.classList.add('show');
    })
    dropdown.addEventListener('mouseleave', () => {
      dropdownToggle.classList.remove('show');
      dropdownToggle.setAttribute('aria-expanded', 'false');
      dropdownMenu.classList.remove('show');
    })
  });

  // Animate transition for mobile menu toggle
  if( document.getElementById('mobile-menu') ) {
    var offcanvas = document.getElementById('mobile-menu');

    offcanvas.addEventListener('show.bs.offcanvas', () => {
      document.querySelector('#mobile-menu-toggle').classList.add('is-active');
    });
    offcanvas.addEventListener('hide.bs.offcanvas', () => {
      document.querySelector('#mobile-menu-toggle').classList.remove('is-active');
    });
  }

  // Validate forms
  var forms = document.querySelectorAll('.needs-validation');

  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      };
      form.classList.add('was-validated');
    }, false);
  });

  // Contact Us form adjustments
  document.addEventListener( 'wpcf7mailsent', function( event ) {
    var form = document.querySelector('.wpcf7');
    var message = document.querySelector('.thank-you-message');
    form.remove();
    message.style.display = 'block';
  }, false );

  // Disclaimer jump links
  const symbols = [...document.querySelectorAll('sup')];
  const disclaimers = [...document.querySelectorAll('#footer-disclaimers li')];

  let disclaimerSymbols = disclaimers.map(disclaimer => {
    return disclaimer.dataset.symbol
  });

  symbols.forEach(symbol => {
    if(disclaimerSymbols.includes(symbol.innerHTML)) {
      symbol.setAttribute('tabindex', '0');
      symbol.classList.add('disclaimer');

      const jumpToDisclaimer = (sup) => {
        let disclaimer = disclaimers.find((symbol) => symbol.dataset.symbol === sup);
        let disclaimerWrapper = disclaimer.querySelector('span');
        let returnKey = disclaimer.querySelector('.return');
        returnKey.style.display = 'inline-block';
        disclaimerWrapper.classList.add('highlighted');
        disclaimer.scrollIntoView({behavior: "instant"});
        returnKey.focus();
      }

      symbol.addEventListener('click', e => {
        jumpToDisclaimer(symbol.innerHTML);
        symbol.classList.add('current');
      })

      symbol.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          jumpToDisclaimer(symbol.innerHTML);
          symbol.classList.add('current');
        }
      })
    }
  })

  disclaimers.forEach(disclaimer => {
    let returnKey = disclaimer.querySelector('.return');

    jQuery(document).ready( function($) {
      $(disclaimer).wrapInner('<span></span>');
    });

    const jumpReturn = () => {
      let symbols = [...document.body.getElementsByTagName('sup')];
      let symbolJump = symbols.find((symbol) => symbol.classList.contains('current'));
      let disclaimerWrapper = disclaimer.querySelector('span');
      returnKey.style.display = 'none';
      disclaimerWrapper.classList.remove('highlighted');
      symbolJump.scrollIntoView({behavior: "instant"});
      symbolJump.focus();
    }

    if(returnKey) {
      returnKey.setAttribute('tabindex', '0');
      
      returnKey.addEventListener('click', e => {
        jumpReturn();
      })

      returnKey.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          jumpReturn();
        }
      })
    }
  })
});

jQuery(document).ready( function($) {
  // Reset disclaimer jumps
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).on('resize scroll', function() {
    if (!$('footer').isInViewport()) {
      $('.disclaimer.return').css('display', 'none');
      $('sup').removeClass('current');
      $('li span').removeClass('highlighted');
    }
  });

  // Osano
  $('.cookie-preferences a').on('click', function() {
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
  );
  
  // Sliders
  if( $('body').hasClass('page-template-small-business-loans-alt') ||
      $('body').hasClass('page-template-loans-temporary') ||
      $('body').hasClass('page-template-loans-contractors') ||
      $('body').hasClass('page-template-loans-cleaning') ||
      $('body').hasClass('page-template-loans-retail') ||
      $('body').hasClass('page-template-policy-security') ) {

    // Slick slider init
    var options = {
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      customPaging: function (slick, index) {
        return '<button type="button" data-slick-index="' + index + '">' +
          '<span class="visually-hidden">Slide ' +
          ( index + 1 ) +
          '</span></button>';
      },
      arrows: false,
      infinite: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    $('.slider').slick(options);
  }

  if( $('body').hasClass('page-template-business-line-of-credit') ||
      $('body').hasClass('page-template-business-term-loan') ||
      $('body').hasClass('page-template-campaign-direct') ) {

    // Slick slider init
    var options = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      customPaging: function (slick, index) {
        return '<button type="button" data-slick-index="' + index + '">' +
          '<span class="visually-hidden">Slide ' +
          ( index + 1 ) +
          '</span></button>';
      },
      nextArrow: '<button type="button" class="slick-arrow slick-next btn btn-outline-light">' + 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15.5" class="arrow"><path d="M7.7 8.5c.4-.4.4-1.1 0-1.6L1.5.3C1.3.1 1.1 0 .9 0 .4 0 0 .3 0 .8c0 .2.1.5.2.6l5.9 6.3L.2 14c-.3.3-.3.9 0 1.2.2.1.4.2.6.2.2 0 .4-.1.6-.3.1.1 6.3-6.6 6.3-6.6z"/></svg>' +
                  '<span class="visually-hidden">Next Slide</span>' +
                '</button>',
      prevArrow: '<button type="button" class="slick-arrow slick-prev btn btn-outline-light">' + 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15.5" class="arrow"><path d="M.3 6.9c-.4.4-.4 1.1 0 1.6l6.2 6.6c.2.2.4.3.6.3.5 0 .9-.3.9-.8 0-.2-.1-.5-.2-.6L1.9 7.7l5.9-6.3c.3-.3.3-.9 0-1.2-.2-.1-.4-.2-.6-.2-.2 0-.4.1-.6.3C6.5.2.3 6.9.3 6.9z"/></svg>' +
                  '<span class="visually-hidden">Previous Slide</span>' +
                '</button>',
      arrows: true,
      infinite: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          arrows: false
        }
      }]
    };

    $('.slider').slick(options);
  }

  // FAQ page search function
  if( $('body').hasClass('page-template-faqs') ) {
    $(document).on('keyup search', '#faq-search', function() {
      var e = $(this).val().replace(/[^\w]/g, '').toLowerCase();
      e ? ($('.accordion').addClass('faq-search-active'), $('.accordion-item').removeClass('faq-search-match').filter(function() {
        var a = $(this).data('faq-plaintext');
        return a || (a = $(this).text().replace(/[^\w]/g, '').toLowerCase(), $(this).data('faq-plaintext', a)), a.match(e)
      }).addClass('faq-search-match')) : ($('.accordion').removeClass('faq-search-active'), $('.accordion-item.faq-search-match').removeClass('.faq-search-match'))
    })
  }

  // Contact Us page formatting fields to use floating labels
  if( $('body').hasClass('page-template-contact-us') ) {
    $('.wpcf7-form-control-wrap').each(function() {
      var label = $(this).next('label').detach();
      var field = $(this).find('.form-control');
      $(this).append(label).addClass('form-floating');
      if(field) {
        field.val('');
      }
    })
  }

  // News page AJAX functions
  if( $('body').hasClass('page-template-news') ) {
    function news_load_all_posts(page) {
      var data = {
        page: page,
        postType: 'post',
        action: 'pagination_load_posts'
      };

      $.post(ondeck_frontend.ajaxurl, data, function(response) {
        $('.news-container').html(response);
      });
    }

    function pr_load_all_posts(page) {
      var data = {
        page: page,
        postType: 'press_release',
        action: 'pagination_load_posts'
      };

      $.post(ondeck_frontend.ajaxurl, data, function(response) {
        $('.pr-container').html(response);
      });
    }

    news_load_all_posts(1);
    pr_load_all_posts(1);

    // Pagination
    $('.news-container').on('click', '.news-pagination a', function(event) {
      event.preventDefault();
      var page = $(this).attr('data-page');
      news_load_all_posts(page);
      $('html, body').animate({scrollTop: $('#news').offset().top}, 0);
    });

    $('.pr-container').on('click', '.pr-pagination a', function(event) {
      event.preventDefault();
      var page = $(this).attr('data-page');
      pr_load_all_posts(page);
      $('html, body').animate({scrollTop: $('#pr').offset().top}, 0);
    });
  }

  // Resource author page AJAX function
  if( $('body').hasClass('author') ) {
    // var author_array = window.location.pathname.split('/');
    // var author = author_array[3];

    // function resource_load_all_posts(page, author) {
    //   var data = {
    //     page: page,
    //     authorName: author,
    //     postType: 'resource',
    //     action: 'pagination_load_posts'
    //   };

    //   $.post(ondeck_frontend.ajaxurl, data, function(response) {
    //     $('.resource-container').html(response);
    //   });
    // }

    // resource_load_all_posts(1, author);

    // Pagination
    if (window.location.href.indexOf('/page/') > -1) {
      $('html, body').animate({scrollTop: $('#articles').offset().top}, 0);
    }
    // $('.author-articles').on('click', '.author-pagination a', function(event) {
      // event.preventDefault();
      // var page = $(this).attr('data-page');
      // var author = $(this).attr('data-author');
      // resource_load_all_posts(page, author);
    // });
  }

  // Limit hero lead form revenue field to digits only, to allow for pattern error validation
  if( $('body').hasClass('page-template-campaign-aadom') ||
  $('body').hasClass('page-template-campaign-affiliate') ||
  $('body').hasClass('page-template-campaign-direct') ||
  $('body').hasClass('page-template-campaign-mgma') || 
  $('body').hasClass('page-template-campaign-partner') ||
  $('body').hasClass('page-template-campaign-sem') ) {
    $('#revenue').on('input', function(e) {
      $(this).val($(this).val().replace(/[^0-9]/gi, ''));
    });
  }
  // Lead Form Query String Population
  if ($('body').hasClass('page-template-campaign-aadom') || $('body').hasClass('page-template-campaign-affiliate') || $('body').hasClass('page-template-campaign-direct') || $('body').hasClass('page-template-campaign-mgma') || $('body').hasClass('page-template-campaign-partner') || $('body').hasClass('page-template-campaign-sem')) {
    function getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");

      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");

        if (pair[0] == variable) {
          return pair[1];
        }
      }

      return "";
    }

    if (window.location.search) {
      document.querySelector('#first-name').value = getQueryVariable('firstName');
      document.querySelector('#last-name').value = getQueryVariable('lastName');
      document.querySelector('#email').value = getQueryVariable('emailAddress');
      document.querySelector('#phone').value = getQueryVariable('phoneNumber');
      document.querySelector('#revenue').value = getQueryVariable('annualRevenue');
    }
  }

  // Rewrite WP Generated Tables (resources)
  let tables = [...document.querySelectorAll(".resource-content table")];

  if( !tables ) {
    //console.log("no tables")
  } else {
    tables.forEach((table) => {
      let headers, 
          headerrow, 
          cols, 
          wrapper;

      //Create Table Head & Move first row to it
      table.prepend(document.createElement('thead'));
      headerrow = table.querySelector('tr:first-of-type');
      wrapper = table.querySelector('thead');
      wrapper.appendChild(headerrow);

      //Rewrite TDs to THs
      [...table.querySelectorAll('thead tr:first-of-type>td')].forEach(
        td => td.outerHTML = td.outerHTML
        .replace(/<td([^>]*)>/, '<th$1>')
        .replace(/<\/td>/, '</th>')
      );

      //Wrap text in tbody tds with div for styling
      [...table.querySelectorAll('tbody tr>td')].forEach(
        td => td.outerHTML = td.outerHTML
        .replace(/<td([^>]*)>/, '<td$1><div>')
        .replace(/<\/td>/, '</div></td>')
      );

      //Get table Headers
      headers = [...table.querySelectorAll('thead th')];

      //Assign Headers per Col
      headers.forEach((header, index) => {
        cols = [...table.querySelectorAll('tbody tr td:nth-of-type(' + ( index + 1 ) + ')')];
        cols.forEach((col, index) => {
          col.dataset.label = header.innerText;
        });
      });
    });
  }

  // Orcolus dropdown navigation
  if( $('body').hasClass('page-template-ocrolus') ) {
    // An alternative to Bootstrap's buggy scrollspy

    $('html').css('scroll-padding-top', '12rem');
    $(window).bind( 'scroll', function() {
      var currentTop = $(window).scrollTop();
      var elems = $('.scrollspy');

      elems.each( function(i) {
        var elemTop = $(this).offset().top;
        var elemBottom = elemTop + $(this).height();
        if( currentTop >= (elemTop - 230) && currentTop <= elemBottom ) {
          var id 		= $(this).attr('id');
          var navElem = $('a[href="#' + id+ '"]');
          var navText = $('a[href="#' + id+ '"]').attr('data-report');
          navElem.addClass('active')
          navElem.parent().siblings().find('a').removeClass( 'active' );
          $('.active-report').text(navText);
        }
      })
    });
  }
});
